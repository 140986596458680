import * as React from 'react';
import { Grid, IconButton, Theme } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { createStyles, makeStyles } from '@mui/styles';
import { ImportSVG, UploadSVG } from 'assets/img';
import { ModalButton } from './Modal.types';
import { ModalDialog } from '../ModalDialog/Modal';
import { showAlert } from 'components/Alert';
import { postImportOpeningBalance } from 'api/locations';
import CloseIcon from '@mui/icons-material/Close';

export type ImportHandles = {
  openImport: () => void;
};

type ImportProps = {
  children?: React.ReactNode;
  url: string;
  refresh?: () => void;
  onComplete?: () => void;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalContainer: {
      width: '100%',
      borderRadius: 16
    },
    tableContainer: {
      width: '100%',
      '& .MuiTableRow-head': {
        background: '#ffffff'
      },
      '& .MuiTableCell-sizeSmall': {
        padding: '14px 25px'
      }
    },
    inputFile: {
      width: '100%'
    },
    inputFileLabel: {
      height: 48,
      width: '100%',
      background: '#F5FAFF',
      display: 'flex',
      alignItems: 'center',
      padding: 15,
      justifyContent: 'space-between'
    },
    downloadFileTemp: {
      height: 30,
      width: '100%',
      background: '#F5FAFF',
      display: 'flex',
      padding: '5px 0px'
    }
  })
);

const ModalImport: React.ForwardRefRenderFunction<ImportHandles, ImportProps> = (props, forwardRef) => {
  const classes = useStyles();

  const [open, setOpen] = React.useState<boolean>(false);
  const [busy, setBusy] = React.useState<boolean>(false);
  const [files, setFiles] = React.useState(null);

  const uploadRef = React.useRef(null);

  const handleImport = async () => {
    try {
      const formData = new FormData();
      formData.append('qquuid', uuidv4());
      formData.append('qqfilename', files.name);
      formData.append('qqtotalfilesize', files.size);
      formData.append('qqfile', files);
      const res: any = await postImportOpeningBalance(formData, props.url);
      if (res.status === 200) {
        showAlert('success', 'Berhasil import data', 5000);
        uploadRef.current.value = null;
        setFiles(null);
        setOpen(false);
        props.onComplete();
        setTimeout(() => {
          setBusy(false);
          props.refresh();
        }, 1000);
      }
    } catch (err) {
      const error = err as Error;
      console.log(error);
      showAlert('error', `${error}`, 5000);
    } finally {
      setBusy(false);
    }
  };

  const ImportButtonAction = (key: string) => {
    switch (key) {
      case null:
        setOpen(false);
        break;
      case 'import':
        handleImport();
        break;
    }
  };

  const ImportButton: ModalButton[] = [
    {
      key: 'import',
      caption: 'Import',
      color: 'primary',
      type: 'submit',
      icon: <ImportSVG className="text-white" />,
      busy: busy
    }
  ];

  const onClearFile = (event) => {
    event.preventDefault();
    uploadRef.current.value = '';
    setFiles(null);
  };

  React.useImperativeHandle(forwardRef, () => ({
    openImport() {
      setOpen(true);
    }
  }));

  return (
    <ModalDialog title={'Import'} open={open} onAction={ImportButtonAction} buttons={ImportButton}>
      <Grid container direction="column" className="p-3">
        <Grid item className={classes.inputFile}>
          <input
            id="import"
            type="file"
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            hidden
            ref={uploadRef}
            onChange={(e) => setFiles(e.target.files[0])}
          />
          <label htmlFor="import" className={classes.inputFileLabel}>
            <span style={{ color: '#62656F', fontSize: '14px' }}>
              {files ? files.name : 'Pilih file yang akan di import'}
            </span>
            {files ? (
              <IconButton onClick={onClearFile}>
                <CloseIcon />
              </IconButton>
            ) : (
              <UploadSVG />
            )}
          </label>
        </Grid>
        <div className="my-2"></div>
        {props.children}
      </Grid>
    </ModalDialog>
  );
};

export default React.forwardRef(ModalImport);
