import React, { useMemo, useRef, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  Tooltip
} from '@mui/material';
import styles from './StepSix.module.scss';
import {
  DescTitle,
  FilterSearch,
  FilterSearchWrap,
  LeftContentWrap,
  RadioOption,
  TextItem
} from '../StepFour/StepThreeStyle';
import { useForm } from 'react-hook-form';
import { createStyles, makeStyles } from '@mui/styles';
import { useAppStore } from 'stores/appStore';
import { AsyncButton } from 'components/ButtonNew/Button';
import { AddSVG, ExportSVG, SaveSVG, SearchSVG } from 'assets/img';
import { LiteGrid } from 'components/LiteGrid/LiteGrid';
import { Image } from 'components/Image/Image';
import { Column } from 'components/LiteGrid/DataGrid.types';
import { showAlert } from 'components/Alert';
import { QueryParameters } from 'lib/types';
import { Theme } from '@mui/material/styles';
import { HorizontalLabel } from 'assets/styles/components/label/HorizontalLabel';

import {
  getDownloadFileExport,
  getListBundle,
  getPrepareExport,
  getUrlExport,
  postActivatedBundle,
  postSaveBundle
} from 'api/masterStore';
import { ModalComposition } from './ModalComposition';

import DeleteIcon from '@mui/icons-material/CancelRounded';
import AddIcon from '@mui/icons-material/AddRounded';
import RemoveIcon from '@mui/icons-material/Remove';
import { Confirmation } from 'components/Modal/Confirmation';
import { ModalExport } from './ModalExport';

interface Props {
  submitRef: React.LegacyRef<HTMLButtonElement> | undefined;
  confirmationRef?: any;
}

interface FormValues {
  q: string;
}

interface OtherFilterProps {
  filter?: string | null;
  type?: string | null;
}

const defaultPagination = {
  q: '',
  page: 1,
  pageSize: 25,
  sortBy: 'item_name',
  sortDirection: 'ASC'
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    boxContainer: {
      background: '#FFFFFF',
      borderRadius: '8px'
    },
    bundleCard: {
      minHeight: '258px',
      minWidth: '172px',
      height: '100%',
      display: 'flex',
      flexFlow: 'column'
    },
    bundleCardAdd: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '258px',
      minWidth: '172px',
      height: '100%',
      background: '#FFFFFF',
      border: '1px solid #EAEAEA',
      boxShadow: '0px 2px 10px rgba(175, 137, 255, 0.15)',
      borderRadius: '15px',
      cursor: 'pointer'
    },
    bundleWrapper: {
      width: '100%',
      display: 'block',
      textAlign: 'initial',
      background: '#FFFFFF',
      border: '1px solid #EAEAEA',
      boxShadow: '0px 2px 10px rgba(175, 137, 255, 0.15)',
      borderRadius: '15px',
      padding: '10px'
    },
    bundleImg: {
      width: '100%',
      aspectRatio: '1',
      objectFit: 'contain',
      borderRadius: '8px'
    },
    bundleWrapText: {
      minHeight: '112px',
      paddingTop: '10px'
    },
    bundleDescWrapper: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: '10px'
    },
    itemCode: {
      fontWeight: 'bold',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontSize: 12,
      display: '-webkit-box',
      '-webkit-line-clamp': 1,
      '-webkit-box-orient': 'vertical'
    },
    itemName: {
      fontSize: 12,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical'
    },
    bundleCounterWrapper: {
      justifyContent: 'space-between',
      alignSelf: 'center'
    },
    bundleCounterOperator: {
      flexBasis: 'auto !important',
      justifyContent: 'center',
      alignSelf: 'center',
      display: 'flex',
      cursor: 'pointer'
    },
    bundleCounterInput: {
      border: 'none',
      textAlign: 'center',
      fontSize: '14px',
      width: '100%',
      backgroundColor: 'transparent'
    },
    bundleRemoveWrapper: {
      position: 'absolute',
      top: '20px',
      right: '-7px',
      cursor: 'pointer',
      background: 'transparent',
      width: '30px',
      height: '30px',
      lineHeight: '30px'
    },
    bundleRemoveIcon: {
      color: '#DF4D4D',
      maxWidth: '18px',
      maxHeight: '18px',
      width: '18px !important',
      height: '18px !important'
    },
    bundleAddIcon: {
      color: '#7a83a8',
      maxWidth: '200px',
      maxHeight: '200px',
      width: '40px !important',
      height: '40px !important'
    }
  })
);

const StepSix: React.FunctionComponent<Props> = ({ submitRef, confirmationRef }) => {
  const classes = useStyles();
  const { setShowConfirmation, updateStep, setDisablePrevButton, canSkip, cantSkip } = useAppStore();
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [busy, setBusy] = useState(false);
  const [bundleItems, setBundleItems] = useState([]);
  const [statusDownload, setStatusDownload] = useState('all');
  const [isShowBundlePicker, setShowBundlePicker] = useState(false);
  const [isDetail, setIsDetail] = useState(false);
  const [valueDetail, setValueDetail] = useState<any>({});
  const [isConfirm, setIsConfirm] = useState(false);
  const [arrayData, setArrayData] = useState([]);
  const [modalExport, setModalExport] = useState(false);
  const [pagination, setPagination] = useState(defaultPagination);
  const [currentTime, setCurrentTime] = useState(new Date());
  const [url, setUrl] = useState('');
  const { register, handleSubmit, setValue } = useForm<FormValues>({
    defaultValues: {
      q: ''
    }
  });

  const query = useRef<QueryParameters>();
  const refresher = useRef<() => void>();
  const setQuery = useRef<(e: QueryParameters) => QueryParameters>();
  const idItem = useRef<number>();

  const handleChangeStatusDownload = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStatusDownload((event.target as HTMLInputElement).value);
  };

  const renderProduct = (_cellData: undefined, rD: any) => {
    const selected = arrayData.find((dat) => dat.item_id == rD.item_id);
    const name = rD.item_name.length > 30 ? `${rD.item_name.slice(0, 30)}...` : rD.item_name;
    return (
      <div className="d-flex align-items-center font-size-table">
        <Image src={rD.thumbnail} height={35} width={35} className="border 1 border-light-gray rounded-sm mt-1 mb-1" />
        <div className="d-flex flex-column ml-3">
          <span className="text-nowrap">{name}</span>
          <span className="font-weight-normal">{rD.item_code}</span>
          {rD.is_bundle && rD.composition[0] !== null && (
            <span className="font-weight-normal" style={{ color: '#1FAF38' }}>
              Terdapat {`${rD.composition.length}`} komposisi
            </span>
          )}
          {selected.is_bundle && rD.composition[0] === null && (
            <span className="font-weight-normal" style={{ color: '#DF4D4D' }}>
              Belum ada komposisi
            </span>
          )}
        </div>
      </div>
    );
  };

  const handleChangeUi = async (value: string, data: any) => {
    if (value === 'next') {
      setDisablePrevButton(true);
      cantSkip();
      setIsDetail(true);
      setValueDetail(data);
      if (data.composition[0] !== null) {
        let newComposition = data.composition.map((val) => ({
          ...val,
          checked: true
        }));
        setBundleItems(newComposition);
      }
    } else if (value === 'back') {
      setDisablePrevButton(false);
      canSkip();
      setIsDetail(false);
      setBundleItems([]);
    }
  };

  const handleSwitch = (data) => {
    setBusy(true);
    const selected = arrayData.findIndex((dat) => dat.item_id == data.item_id);
    arrayData[selected].is_bundle = !arrayData[selected].is_bundle;
    setTimeout(() => {
      setBusy(false);
    }, 200);
  };

  const handleChangeSwitch = (data) => {
    idItem.current = data.item_id;
    if (data.is_bundle) {
      setIsConfirm(true);
    } else {
      handleSwitch(data);
    }
  };

  const columns: Column[] = [
    {
      header: 'Produk',
      binding: 'item_name',
      format: 'string',
      allowSorting: false,
      freezeCol: true,
      template: renderProduct
    },
    {
      header: 'Jadikan Bundle',
      headerClassName: 'text-center text-second text-nowrap',
      allowSorting: false,
      binding: 'is_bundle',
      template: (ctx, data) => {
        const selected = arrayData.find((dat) => dat.item_id == data.item_id);
        return (
          <div className="text-center">
            <Switch
              onChange={() => (selected.is_bundle ? handleChangeSwitch(data) : handleSwitch(data))}
              checked={selected.is_bundle}
              className="switch-extrasmall m-1"
            />
          </div>
        );
      }
    },
    {
      header: '',
      headerClassName: 'text-right text-second text-nowrap',
      allowSorting: false,
      binding: 'count_composition',
      template: (ctx, data) => {
        const selected = arrayData.find((dat) => dat.item_id == data.item_id);
        return (
          <div className={`text-right ${!selected.is_bundle && 'd-none'}`}>
            <AsyncButton
              onClick={() => handleChangeUi('next', data)}
              variant="outlined"
              icon={<AddSVG color="#163A50" />}
              color="inherit"
              type="button"
            >
              Komposisi
            </AsyncButton>
          </div>
        );
      }
    }
  ];

  const getQuery = (q: QueryParameters) => {
    query.current = q;
  };

  const getSetQuery = (fn) => {
    setQuery.current = fn;
  };

  const getFetcher = async (options: QueryParameters & OtherFilterProps) => {
    setBusy(true);
    let newOptions: any = { ...options };
    newOptions.type = 'variant';

    const res = await getListBundle(newOptions);

    delete newOptions.type;
    delete newOptions.filter;
    newOptions.bundleFilter = 1;
    newOptions.csv = true;

    setPagination(newOptions);

    const data = {
      ...res,
      data: res?.data?.data || [],
      totalCount: res?.data?.total_count || '0'
    };

    setArrayData(
      data.data.map((val) => {
        return {
          item_id: val.item_id,
          is_bundle: val.is_bundle
        };
      })
    );

    setBusy(false);
    return data;
  };

  const fetcher = useMemo(() => {
    return (options: QueryParameters) => {
      return getFetcher(options);
    };
  }, []);

  const handleReset = async () => {
    const newQuery: QueryParameters & OtherFilterProps = {
      ...query.current,
      q: '',
      page: 1
    };
    newQuery.filter = 'all';
    setValue('q', '');
    setStatusDownload('all');
    setQuery.current(newQuery);
  };

  const handleSubmitSearch = async (formvalue) => {
    const newQuery: QueryParameters & OtherFilterProps = {
      ...query.current,
      q: formvalue.q,
      page: 1
    };
    delete newQuery.filter;

    if (statusDownload !== 'all') {
      newQuery.filter = statusDownload;
    }
    setQuery.current(newQuery);
  };

  const handleCounterChange = (value, i) => {
    const bundleItemx = bundleItems.slice();
    const lastVal = parseInt(bundleItemx[i].qty);
    const newVal = lastVal + parseInt(value);
    if (newVal >= 1) {
      bundleItemx[i].qty = Number(newVal);
      setBundleItems(bundleItemx);
    }
  };

  const handleCounterInputChange = (e, i) => {
    const bundleItemx = bundleItems.slice();
    const inputVal = e.target.value;
    const value = inputVal != '' && parseInt(inputVal) != 0 && !isNaN(parseInt(inputVal)) ? parseInt(inputVal) : 1;
    bundleItemx[i].qty = Number(value);
    setBundleItems(bundleItemx);
  };

  const handleRemove = (val) => {
    const bundleItemx = bundleItems.filter((x) => x.item_code !== val.item_code);
    setBundleItems(bundleItemx);
  };

  const onBundlePickerClose = () => {
    setShowBundlePicker(false);
  };

  const onBundlePickerConfirm = (data) => {
    setBundleItems(data);
    setShowBundlePicker(false);
  };

  const handleCounterInputFocus = (e) => e.target.select();

  const submitSaveBundle = async () => {
    try {
      let payload = {
        item_id: valueDetail.item_id,
        items: bundleItems.map((val) => ({ item_id: val.item_id, qty: val.qty }))
      };
      const res: any = await postSaveBundle(payload);
      if (res.data.status === 'ok') {
        setDisablePrevButton(false);
        canSkip();
        setBundleItems([]);
        setIsDetail(false);
        showAlert('success', 'Komposisi produk berhasil!', 5000);
      }
    } catch (err) {
      const error = err as Error;
      console.log(error);
      showAlert('error', `${error}`, 5000);
    }
  };

  const confirmActionBtn = {
    label: 'Ya',
    color: 'btn-primary'
  };

  const onCloseModalConfirm = async (value) => {
    if (value) {
      try {
        const res: any = await postActivatedBundle(idItem.current);
        if (res.data.status === 'ok') {
          refresher.current();
          setIsConfirm(false);
          showAlert('success', 'Nonaktifkan bundle berhasil!', 5000);
        }
      } catch (err) {
        const error = err as Error;
        console.log(error);
        showAlert('error', `${error}`, 5000);
      }
    } else {
      setIsConfirm(false);
    }
  };

  const getExportUrl = async (time) => {
    try {
      const res: any = await getUrlExport(time.getTime());
      if (res.status === 200) {
        setUrl(res?.data?.jobs[0]?.result?.url);
        setBusy(false);
      }
    } catch (err) {
      const error = err as Error;
      console.log(error);
      showAlert('error', `${error}`, 5000);
    }
  };

  const handleExport = async () => {
    try {
      setBusy(true);
      let newCurrentTime = new Date();
      let payload: any = { ...pagination };
      payload.export_id = newCurrentTime.getTime();
      setCurrentTime(newCurrentTime);
      setModalExport(true);
      const res: any = await getPrepareExport(payload);
      if (res.status === 200) {
        setTimeout(() => {
          getExportUrl(newCurrentTime);
        }, 1000);
      }
    } catch (err) {
      const error = err as Error;
      console.log(error);
      showAlert('error', `${error}`, 5000);
    }
  };

  const onExportClose = () => {
    setModalExport(false);
  };

  const onSubmitExport = async () => {
    try {
      const res: any = await getDownloadFileExport(url);
      let fileName = url.split('/');

      const windowUrl = window.URL || window.webkitURL;
      if (typeof windowUrl.createObjectURL === 'function') {
        const blob = new Blob([res.data], { type: 'text/bin' });
        const URL = windowUrl.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.href = URL;
        anchor.setAttribute('download', `${fileName[5]}.csv`);
        anchor.click();
        windowUrl.revokeObjectURL(URL);
      }
    } catch (err) {
      const error = err as Error;
      console.log(error);
      showAlert('error', `${error}`, 5000);
    }
  };

  const handleClick = () => {
    if (!isConfirmed) {
      setShowConfirmation(true);
    }
  };

  const handleClickConfirmation = () => {
    setIsConfirmed(true);
    updateStep('INCREASE');
  };

  return (
    <>
      <Grid>
        <div className={`mb-3 ${styles.title}`}>Pengaturan Produk Bundle</div>
        <div className={`mb-3 ${styles.titleDescription}`}>
          Bundle adalah penggabungan beberapa item atau produk menjadi satu set produk dan dijual dengan satu harga
          <br />
          Contoh: SKU Produk A Rp 30.000 + SKU Produk B Rp 25.000 digabung menjadi SKU Produk AB, dan dijual dengan
          harga Rp 50.000 Lewati step ini jika Kamu belum mengatur produk bundle. Untuk memahami tentang produk bundle
          lebih lanjut, silakan&nbsp;
          <a
            href="https://v2.edu.jubelio.com/documentation/fitur-katalog/produk-di-jubelio-2/produk-bundle-dan-cara-membuatnya-2/"
            target={'_blank'}
            rel="noreferrer"
          >
            klik disini
          </a>
        </div>
        <Divider sx={{ my: '30px' }} />
        {!isDetail && (
          <>
            <Grid container className="text-right">
              <Grid item md={9} />
              <Grid item md={3} style={{ color: '#fff' }}>
                <AsyncButton onClick={handleExport} type="button" variant="outlined" color="inherit">
                  <ExportSVG className="mr-2" />
                  Export
                </AsyncButton>
              </Grid>
            </Grid>

            <Grid container spacing={3} className="mt-2">
              <Grid item md={3}>
                <LeftContentWrap>
                  <form onSubmit={handleSubmit(handleSubmitSearch)}>
                    <FilterSearch>
                      <FilterSearchWrap>
                        <p className="m-0">Filter</p>
                        <AsyncButton
                          color="inherit"
                          type="button"
                          className="text-danger px-0 hover-none"
                          onClick={handleReset}
                        >
                          Reset
                        </AsyncButton>
                      </FilterSearchWrap>
                      <TextField
                        disabled={busy}
                        variant="outlined"
                        size="small"
                        className="bg-white rounded-sm no-notch mb-3"
                        placeholder="Cari Produk, SKU Produk"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchSVG />
                            </InputAdornment>
                          )
                        }}
                        {...register('q')}
                      />
                    </FilterSearch>
                    <RadioOption>
                      <DescTitle>Tipe</DescTitle>
                      <FormControl>
                        <RadioGroup value={statusDownload} onChange={handleChangeStatusDownload}>
                          <FormControlLabel
                            disabled={busy}
                            value="all"
                            className="mb-1"
                            control={<Radio sx={{ padding: '2px', marginLeft: '10px' }} size="small" />}
                            label={<TextItem>Semua</TextItem>}
                          />
                          <FormControlLabel
                            disabled={busy}
                            value="bundle"
                            className="mb-1"
                            control={<Radio sx={{ padding: '2px', marginLeft: '10px' }} size="small" />}
                            label={<TextItem>Bundle</TextItem>}
                          />
                          <FormControlLabel
                            disabled={busy}
                            value="bundle-empty"
                            className="mb-1"
                            control={<Radio sx={{ padding: '2px', marginLeft: '10px' }} size="small" />}
                            label={<TextItem>Bundle belum terisi</TextItem>}
                          />
                          <FormControlLabel
                            disabled={busy}
                            value="non-bundle"
                            className="mb-1"
                            control={<Radio sx={{ padding: '2px', marginLeft: '10px' }} size="small" />}
                            label={<TextItem>Non bundle</TextItem>}
                          />
                        </RadioGroup>
                      </FormControl>
                    </RadioOption>
                    <div className="mt-3">
                      <AsyncButton fullWidth type="submit" color="primary" disabled={busy}>
                        Terapkan
                      </AsyncButton>
                    </div>
                  </form>
                </LeftContentWrap>
              </Grid>
              <Grid item md={9}>
                <LiteGrid
                  flexibleWidth={true}
                  columns={columns}
                  data={fetcher}
                  getRefresher={(e) => (refresher.current = e)}
                  getQuery={getQuery}
                  getSetQuery={getSetQuery}
                  customHeight="calc(100vh - 327px)"
                />
              </Grid>
            </Grid>
          </>
        )}
      </Grid>

      {isDetail && (
        <Box className={`${classes.boxContainer} mb-3`}>
          <Grid container className="mb-4 d-flex align-items-center justify-content-between">
            <Grid item md={4} className="font-weight-bold" style={{ fontSize: '17px' }}>
              Pilih Komposisi Produk
            </Grid>
            <Grid item md={4} className="text-right" style={{ color: '#fff' }}>
              <Button
                onClick={(data) => handleChangeUi('back', data)}
                className="mr-2 py-3 px-4"
                type="button"
                variant="outlined"
                color="inherit"
              >
                Batalkan
              </Button>
              <AsyncButton
                disabled={bundleItems?.length < 1}
                className="px-3"
                variant="contained"
                type="button"
                icon={<SaveSVG />}
                color="primary"
                onClick={submitSaveBundle}
              >
                <p className="m-0 text-white">Simpan</p>
              </AsyncButton>
            </Grid>
          </Grid>
          <HorizontalLabel
            title="Komponen Produk"
            desc="Pilih produk yang akan dijadikan komponen bundle."
            disableLabelMarginTop
          >
            <Grid container spacing={3} className="mb-3">
              {bundleItems &&
                bundleItems.map((x, key) => (
                  <Grid item key={key} md={4} lg={4}>
                    <Card className={`${classes.bundleCard}`}>
                      <div className={classes.bundleWrapper}>
                        <CardMedia className={`${classes.bundleImg}`} image={x.thumbnail} />
                        <CardContent className={`${classes.bundleWrapText} px-0 pb-1`}>
                          <Grid container className={classes.bundleDescWrapper}>
                            <Grid item md={5}>
                              <span className={classes.itemCode}>Qty</span>
                            </Grid>
                            <Grid item md={7}>
                              <Grid container className={classes.bundleCounterWrapper}>
                                <Grid item xs={3} className={classes.bundleCounterOperator}>
                                  <RemoveIcon
                                    onClick={() => {
                                      handleCounterChange(-1, key);
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <input
                                    className={classes.bundleCounterInput}
                                    value={x.qty}
                                    onChange={(e) => handleCounterInputChange(e, key)}
                                    onFocus={handleCounterInputFocus}
                                  />
                                </Grid>
                                <Grid item xs={3} className={classes.bundleCounterOperator}>
                                  <AddIcon
                                    onClick={() => {
                                      handleCounterChange(1, key);
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                          <span className={classes.itemCode}>{x.item_code}</span>
                          <Tooltip title={x.item_name}>
                            <span className={classes.itemName}>{x.item_name}</span>
                          </Tooltip>
                        </CardContent>
                      </div>
                    </Card>
                    <div className={classes.bundleRemoveWrapper} onClick={() => handleRemove(x)}>
                      <DeleteIcon className={classes.bundleRemoveIcon} />
                    </div>
                  </Grid>
                ))}
              <Grid item md={4} lg={4}>
                <Card className={classes.bundleCardAdd} onClick={() => setShowBundlePicker(true)}>
                  <AddIcon className={classes.bundleAddIcon} />
                </Card>
              </Grid>
            </Grid>
          </HorizontalLabel>
        </Box>
      )}

      <Confirmation
        open={isConfirm}
        text="Nonaktifkan Bundle?"
        subText="Komposisi akan terlepas dari bundle. Apakah Kamu yakin akan menonaktifkan bundle?"
        primaryButton={confirmActionBtn}
        onAction={onCloseModalConfirm}
      />

      <ModalComposition
        valueDetail={valueDetail}
        isShow={isShowBundlePicker}
        selectedData={bundleItems || []}
        onClose={onBundlePickerClose}
        onSubmit={onBundlePickerConfirm}
      />

      <ModalExport
        isDisabled={busy}
        currentTime={currentTime}
        isShow={modalExport}
        refreshTime={handleExport}
        onClose={onExportClose}
        onSubmit={onSubmitExport}
      />

      <button ref={confirmationRef} onClick={handleClickConfirmation} style={{ display: 'none' }}>
        Confirmation Ref
      </button>
      <button ref={submitRef} onClick={handleClick} style={{ display: 'none' }}>
        CLICK
      </button>
    </>
  );
};

export default StepSix;
